"use client";

import { PropsWithChildren } from "react";
import { Toaster } from "@/components/ui/sonner";
import { getQueryClient } from "@/utils/get-query-client";
import { QueryClientProvider } from "@tanstack/react-query";
import { CameraProvider } from "@/components/ui/camera/camera-provider";

const Provider = ({ children }: PropsWithChildren) => {
  return (
    <CameraProvider>
      <QueryClientProvider client={getQueryClient()}>
        <Toaster />
        {children}
      </QueryClientProvider>
    </CameraProvider>
  );
};

export default Provider;
